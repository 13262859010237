<template>
  <v-radio
    :class="classes"
    :value="value"
    :label="label"
    :disabled="disabled"
    density="compact"
    :ripple="false"
  />
</template>

<script lang="ts">
import { computed } from 'vue'

import { RadioButtonSize, VueClass } from 'komodo2/types.ts'

export default {
  name: 'KomodoRadioButton'
}
</script>

<script setup lang="ts">
interface Props {
  value: string | number
  label?: string
  size?: RadioButtonSize
  disabled?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  label: '',
  size: RadioButtonSize.Default,
  disabled: false
})

const classes = computed((): VueClass => {
  return {
    'k2-radio-button': true,
    'k2-radio-button--small': props.size === RadioButtonSize.Small,
    'k2-radio-button--disabled': props.disabled
  }
})
</script>

<style lang="scss" scoped>
.k2-radio-button {
  --v-selection-control-size: 28px;
  --v-hover-opacity: 1;

  :deep(.v-label) {
    @include k2-color-text-primary-text;

    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.01px;
    opacity: 1;
    margin-left: 4px;
  }

  :deep(.v-icon) {
    @include k2-color-secondary-lighten-2;

    transform: scale(0.67);
  }

  :deep(.v-selection-control__input) {
    margin-right: 0;
    transition: background-color 0.125s linear;
  }

  :deep(.v-selection-control__input::before) {
    @include k2-background-surface-card-element-bg-hover;

    height: 28px;
    width: 28px;
  }

  &--small {
    :deep(.v-icon) {
      transform: scale(0.5)
    }
  }

  &--disabled {
    :deep(.v-label) {
      @include k2-color-text-disabled-text;
    }

    :deep(.v-icon) {
      @include k2-color-text-disabled-text;
    }
  }

  &.v-selection-control--dirty {
    :deep(.v-icon) {
      @include k2-color-main-tegus-blue;
    }

    :deep(.v-selection-control__input::before) {
      @include k2-background-primary-lighten-5;
    }
  }
}
</style>
